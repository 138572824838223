import * as React from "react";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import format from "date-fns/format";
import { Link } from "gatsby";
import styled from "styled-components-themed";
import { Box, Button, Heading, Text, Menu } from "grommet";
import { FaEllipsisH, FaHome } from "react-icons/fa";
import { MdAddCircle, MdArrowForward } from "react-icons/md";
import {
  Anchor,
  Breadcrumbs,
  Spinning,
  StepCard,
  ProgressBar,
} from "shared-ui";
import { AuthService } from "authentication";
import { deleteProject } from "api/projects";
import {
  assessments as organizationAssessments,
  assessmentMap as organizationAssessmentMap,
} from "assessment/src/data/organization";
import { getAssessmentScore } from "assessment/src/pages/Assessment/utils";
import { getAssessments } from "api/assessments";
import { loadOrganization } from "organization";
import { loadProjects, unloadProjects } from "projects";
import { selectProjectsProgressValue } from "@rr/utils/projects/getProgressValue";
import SEO from "../components/seo";
import { RootState } from "../reducers";
import { NavigateFn } from "@reach/router";
import skilledVolunteerIcon from "frontend-app/src/images/icon-skilled-volunteer.svg";
import oneIcon from "frontend-app/src/images/one.png";

const Wrapper = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;
`;

const SectionHeading = styled(Box)`
  margin-bottom: 1rem;
  padding: 0.25rem 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.assessmentBorder};
`;

const StyledRow = styled.div`
  padding: 1rem 1.25rem;
  background: #f8faf9;
  border: 1px solid #b2b2b2;
  border-radius: 0.25rem;
  margin: 0.75rem 0;
`;

const Metadata = styled.p`
  font-size: 0.75rem;
  color: #9bb1a7;
`;

const StyledMenu = styled(Menu)`
  > div {
    padding: 0;
  }
`;

interface Props {
  navigate: NavigateFn;
  projects: RootState["projects"]["projects"];
  organization: RootState["organizations"]["organization"];
  loadProjectsAction: typeof loadProjects;
  unloadProjectsAction: typeof unloadProjects;
  loadOrganizationAction: typeof loadOrganization;
  isLoggedIn: boolean;
}

const IndexPage: React.FunctionComponent<Props> = (props): JSX.Element => {
  const {
    navigate,
    projects,
    organization,
    loadProjectsAction,
    unloadProjectsAction,
    loadOrganizationAction,
    isLoggedIn,
  } = props;

  const [assessment, setAssessment] = React.useState<any>();
  const hasAssessment = assessment && assessment.length > 0;
  const assessmentScoreResult =
    hasAssessment &&
    getAssessmentScore(organizationAssessments, assessment[0].totalScore);
  const assessmentScoreContent =
    assessmentScoreResult &&
    organizationAssessmentMap[
      assessmentScoreResult.label as keyof typeof organizationAssessmentMap
    ];

  React.useEffect(() => {
    if (!isLoggedIn) {
      AuthService.login();
    }
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        if (isLoggedIn && organization) {
          const response = await getAssessments(organization._id);
          const { data } = response.data;
          setAssessment(data);
        }
      } catch (error) {
        console.log("Error: ", error);
      }
    })();
  }, [organization]);

  React.useEffect(() => {
    (async () => {
      if (isLoggedIn) {
        await loadProjectsAction();
        await loadOrganizationAction();
      }
    })();

    return () => {
      unloadProjectsAction();
    };
  }, []);

  if (!isLoggedIn) {
    return <Spinning />;
  }

  return (
    <Wrapper>
      <SEO title="My Projects" />
      <Breadcrumbs>
        <Anchor href="https://www.capacitycommons.org/">
          <FaHome size="16px" color="#238DC1" />
        </Anchor>
        <div>My Projects</div>
      </Breadcrumbs>

      <Heading level={1} margin={{ vertical: "medium" }} color="#244B5A">
        My Projects
      </Heading>

      <Box margin={{ vertical: "medium" }}>
        <SectionHeading direction="row" justify="between">
          <Heading level={4} color="#00B189">
            {projects.length} Projects
          </Heading>
          <Link to={`/projects/new`}>
            <Button
              plain={true}
              icon={<MdAddCircle color=" #238DC1" size="16px" />}
              gap="xsmall"
              label="New Project"
            />
          </Link>
        </SectionHeading>
        {projects.map((project) => {
          const hasProjectNeedAnswers =
            project.projectNeed &&
            project.projectNeed.length > 0 &&
            project.projectNeed.every((question) => question.answer !== "");

          const hasMatchedPartners =
            project.matchedPartners && project.matchedPartners.length > 0;

          const partners = project.matchedPartners
            ? project.matchedPartners.reduce(
                ({ status, matches }, partner) => {
                  matches += partner.matches;
                  switch (status.toLowerCase()) {
                    case "matched":
                      status =
                        partner.status.toLowerCase() === "completed"
                          ? partner.status
                          : status;
                      break;
                    case "completed":
                      break;
                    default:
                      status = partner.status;
                  }

                  let out =
                    status.toLowerCase() !== "matched" &&
                    status.toLowerCase() !== "completed"
                      ? `${matches} ${status}`
                      : status;

                  return {
                    status,
                    matches,
                    out,
                  };
                },
                { status: "", matches: 0, out: "" },
              )
            : { status: "", matches: 0, out: "" };

          const renderProspectiveMatchesHeader = () => {
            if (
              partners.status.toLowerCase() !== "matched" &&
              partners.status.toLowerCase() !== "completed"
            ) {
              return (
                <Box direction="row" align="end">
                  <img
                    src={skilledVolunteerIcon}
                    alt="Skilled Volunteer"
                    style={{ width: "18px", marginRight: "0.5rem" }}
                  />
                  <Text as="p" size=".75em" weight="bold">
                    Prospective Matches
                  </Text>
                </Box>
              );
            }
          };

          const renderProspectiveMatchesValue = () => {
            if (
              partners.status.toLowerCase() !== "matched" &&
              partners.status.toLowerCase() !== "completed"
            ) {
              return (
                <Heading
                  level={3}
                  margin={{ top: "1rem" }}
                  color="#00B189"
                  size="large"
                  style={{
                    textAlign: "end",
                    textTransform: "capitalize",
                  }}
                >
                  {partners.matches}
                </Heading>
              );
            }
          };

          return (
            <StyledRow key={project._id} data-testid="project-row">
              <Box direction="row" justify="between">
                <Metadata>
                  <strong>Project</strong> -{" "}
                  {format(project.createdAt, "MMMM DD, YYYY")}
                </Metadata>
                <StyledMenu
                  size="small"
                  icon={<FaEllipsisH />}
                  dropAlign={{ top: "bottom", right: "right" }}
                  items={[
                    {
                      label: "Continue",
                      onClick: () => {
                        navigate(`/projects/${project._id}/scope`);
                      },
                    },
                    {
                      label: "Delete",
                      onClick: async () => {
                        await deleteProject(project._id!);
                        await loadProjectsAction();
                      },
                    },
                  ]}
                />
              </Box>

              <Box direction="row-responsive" justify="between">
                <Box style={{ maxWidth: "43.75rem" }}>
                  <Heading level={3} margin={{ top: "1.25rem" }}>
                    <Anchor as={Link} to={`/projects/${project._id}/scope`}>
                      {project.name}
                    </Anchor>
                  </Heading>
                  {hasProjectNeedAnswers && (
                    <Text as="p" size="1rem" margin={{ top: "1rem" }}>
                      {project
                        .projectNeed!.map((item) =>
                          item.textarea
                            ? item.textarea.leadingText + item.answer
                            : item.answer,
                        )
                        .join(" ")}
                    </Text>
                  )}
                </Box>
                {hasMatchedPartners ? (
                  <StepCard
                    style={{ paddingTop: 0 }}
                    render={() => (
                      <Box>
                        <Box direction="row">
                          <Box basis="1/2" align="start">
                            {renderProspectiveMatchesHeader()}
                          </Box>
                          <Box basis="1/2" align="start" justify="end">
                            {partners.status && (
                              <Text as="p" size=".75em" weight="bold">
                                Project Status
                              </Text>
                            )}
                          </Box>
                        </Box>
                        <Box direction="row">
                          <Box basis="1/2" align="start">
                            {renderProspectiveMatchesValue()}
                          </Box>
                          <Box basis="1/2" align="start">
                            <Heading
                              level={3}
                              margin={{ top: "1rem" }}
                              color="#00B189"
                              size="large"
                              style={{
                                textAlign: "end",
                                textTransform: "capitalize",
                              }}
                            >
                              {partners.status}
                            </Heading>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  />
                ) : project.percentCompleted === 100 ? (
                  <StepCard
                    icon={() => (
                      <img
                        src={skilledVolunteerIcon}
                        alt="Skilled Volunteer"
                        style={{ width: "24px", marginRight: "0.5rem" }}
                      />
                    )}
                    title="Skilled Volunteers"
                    subtitle="Matches"
                    style={{ paddingTop: 0 }}
                    render={() => (
                      <Box align="end">
                        <Link to={`/projects/${project._id}/volunteers`}>
                          <Button
                            primary={true}
                            icon={<MdArrowForward />}
                            reverse={true}
                            label="Find"
                          />
                        </Link>
                      </Box>
                    )}
                  />
                ) : (
                  <StepCard
                    icon={() => (
                      <img
                        src={oneIcon}
                        alt="One"
                        style={{ width: "24px", marginRight: "0.5rem" }}
                      />
                    )}
                    title="Project Scope"
                    subtitle="Completed"
                    style={{ paddingTop: 0 }}
                    render={() => (
                      <ProgressBar
                        barColor="#00B189"
                        value={project.percentCompleted}
                        renderValue={({ value }: any) => (
                          <Heading
                            level={3}
                            margin={{ top: "1rem" }}
                            color="#00B189"
                            size="xlarge"
                          >
                            {value}%
                          </Heading>
                        )}
                        total={100}
                      />
                    )}
                  />
                )}
              </Box>
            </StyledRow>
          );
        })}
      </Box>

      <Box margin={{ vertical: "medium" }}>
        <SectionHeading direction="row" justify="between">
          <Heading level={4} color="#00B189">
            Organizational Readiness
          </Heading>
        </SectionHeading>

        {organization && (
          <StyledRow>
            {hasAssessment &&
            assessmentScoreContent &&
            assessmentScoreResult ? (
              <React.Fragment>
                <Box direction="row" justify="between">
                  <Metadata>
                    <strong>Assessment</strong> -{" "}
                    {format(assessment[0].createdAt, "MMMM DD, YYYY")}
                  </Metadata>
                </Box>

                <Box direction="row-responsive" justify="between">
                  <Box style={{ maxWidth: "43.75rem" }}>
                    <Heading level={3} margin={{ top: "1.25rem" }}>
                      <Anchor as={Link} to={`/organization/assessment/results`}>
                        Organizational Readiness Assessment
                      </Anchor>
                    </Heading>
                    <Text as="p" size="1rem" margin={{ top: "1rem" }}>
                      <strong
                        style={{
                          color: `${assessmentScoreContent.color}`,
                        }}
                      >
                        {assessmentScoreResult.label}
                      </strong>
                      :{"\n"}
                      {assessmentScoreContent.shortBlurb}
                    </Text>
                  </Box>
                  <StepCard
                    icon={() => {
                      const AssessmentIcon = assessmentScoreContent.icon;
                      return (
                        <AssessmentIcon
                          style={{ marginRight: "0.5rem" }}
                          color={assessmentScoreContent.color}
                          size="24px"
                        />
                      );
                    }}
                    title={assessmentScoreResult.label}
                    subtitle="Score"
                    action={() => (
                      <Link to={`/organization/assessment/results`}>
                        <Button
                          primary={true}
                          icon={<MdArrowForward />}
                          reverse={true}
                          label="Retake"
                        />
                      </Link>
                    )}
                    style={{ paddingTop: 0 }}
                    render={() => (
                      <ProgressBar
                        label={`out of ${assessment[0].maxScore}`}
                        barColor={assessmentScoreContent.color}
                        value={assessment[0].totalScore}
                        renderValue={({ value }: any) => (
                          <Heading
                            level={3}
                            margin={{ top: "1rem" }}
                            color={assessmentScoreContent.color}
                            size="xlarge"
                          >
                            {value}
                          </Heading>
                        )}
                        total={assessment[0].maxScore}
                      />
                    )}
                  />
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Box
                  direction="row-responsive"
                  justify="between"
                  align="center"
                >
                  <Box style={{ maxWidth: "700px" }}>
                    <Heading level={3} margin={{ top: "1.25rem" }}>
                      <Anchor as={Link} to={`/organization/assessment`}>
                        Evaluate Your Organizational Readiness
                      </Anchor>
                    </Heading>
                    <Text as="p" size="1rem" margin={{ vertical: "1rem" }}>
                      The purpose of the Organizational Readiness Assessment is
                      to help you understand whether your organization is ready
                      to leverage the pro bono services delivered by skilled
                      volunteers to better meet your mission.
                    </Text>
                  </Box>
                  <Box align="end">
                    <Link to={`/organization/assessment`}>
                      <Button
                        primary={true}
                        icon={<MdArrowForward />}
                        reverse={true}
                        label="Start"
                      />
                    </Link>
                  </Box>
                </Box>
              </React.Fragment>
            )}
          </StyledRow>
        )}
      </Box>
    </Wrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  projects: state.projects.projects.map((proj) => ({
    ...proj,
    percentCompleted: selectProjectsProgressValue(state, proj._id),
  })),
  organization: state.organizations.organization,
  isLoggedIn: state.session.isLoggedIn,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      loadProjectsAction: loadProjects,
      unloadProjectsAction: unloadProjects,
      loadOrganizationAction: loadOrganization,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IndexPage);
